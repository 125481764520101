<script setup lang="ts">
defineRobotMeta();

const { title } = useAppConfig();
const config = useRuntimeConfig();

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - ${title}` : title;
  },
});

const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,
});
</script>

<template>
  <Html :lang="head.htmlAttrs!.lang" :dir="head.htmlAttrs!.dir">
    <Head>
      <Link
        v-for="link in head.link"
        :id="link.id"
        :key="link.id"
        :rel="link.rel"
        :href="link.href"
        :hreflang="link.hreflang"
      />
      <Meta
        v-for="meta in head.meta"
        :id="meta.id"
        :key="meta.id"
        :property="meta.property"
        :content="meta.content"
      />
    </Head>

    <Body>
      <NuxtLoadingIndicator />

      <ClientOnly>
        <NotificationGroup>
          <div
            class="fixed z-[5000] inset-0 flex items-start justify-end p-6 px-4 py-6 pointer-events-none"
          >
            <div class="w-full max-w-sm">
              <Notification
                v-slot="{ notifications }"
                enter="transform ease-out duration-300 transition"
                enter-from="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4"
                enter-to="translate-y-0 opacity-100 sm:translate-x-0"
                leave="transition ease-in duration-500"
                leave-from="opacity-100"
                leave-to="opacity-0"
                move="transition duration-500"
                move-delay="delay-300"
              >
                <div
                  class="flex w-full max-w-sm mx-auto mt-4 overflow-hidden bg-primary text-white rounded-lg shadow-md"
                  v-for="notification in notifications"
                  :key="notification.id"
                >
                  <div class="px-4 py-2 -mx-3">
                    <div class="mx-3">
                      <span
                        class="font-semibold"
                        :class="{
                          'text-red-500': notification.type === 'error',
                          'text-green-500': notification.type === 'success',
                          'text-blue-500': notification.type === 'info',
                        }"
                        >{{ notification.title }}</span
                      >
                      <p class="text-sm">
                        {{ notification.text }}
                      </p>
                    </div>
                  </div>
                </div>
              </Notification>
            </div>
          </div>
        </NotificationGroup>
      </ClientOnly>

      <NuxtLayout>
        <div id="top" class="font-sans">
          <Header />
          <NuxtPage />
          <Footer />
        </div> </NuxtLayout
    ></Body>
  </Html>
</template>
