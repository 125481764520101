import { setLocale as setYupLocale } from 'yup';
import { configure } from 'vee-validate';
import { localize, setLocale } from '@vee-validate/i18n';
import en from '@vee-validate/i18n/dist/locale/en.json';
import de from '@vee-validate/i18n/dist/locale/de.json';
import yupGerman from './validation/de.js';

export default defineNuxtPlugin(() => {
  configure({
    generateMessage: localize({
      en,
      de,
    }),
  });

  setLocale('de');
  setYupLocale(yupGerman);

  return {};
});
