<template>
  <header class="container">
    <div class="flex items-center justify-between flex-col sm:flex-row">
      <NuxtLink class="text-center flex justify-center flex-col bg-primary px-10 py-4" to="/">
        <span class="sr-only">More at Home</span>
        <img alt="More at Home" class="h-8 w-auto sm:h-16 block" src="/logo-inverted.svg" />
      </NuxtLink>

      <div class="ml-auto mr-auto mt-5 sm:mt-0 sm:mr-10 min-h-10 max-h-16 overflow-hidden">
        <div id="badge-ribbon" class="border rounded-lg"></div>
      </div>

      <div class="flex flex-row items-center mt-5 sm:mt-0">
        <div class="flex flex-col items-end gap-y-1">
          <div>
            <a
              href="tel:+4940468956960"
              class="group flex items-center text-lg hover:text-primary-500"
            >
              <span>+49 (0)40 468 956 96-0</span>
              <span class="block px-1 rounded ml-3 bg-primary text-white group-hover:text-action">
                <Icon
                  name="material-symbols-light:phone-enabled"
                  color="currentColor"
                  size="1.2rem"
                ></Icon
              ></span>
            </a>
          </div>
          <div>
            <a
              href="mailto:partner@moreathome.de"
              class="group flex items-center text-lg hover:text-primary-500"
            >
              <span>partner@moreathome.de</span>
              <span class="block px-1 rounded ml-3 bg-primary text-white group-hover:text-action">
                <Icon name="ic:round-mail-outline" color="currentColor" size="1.2rem"></Icon
              ></span>
            </a>
          </div>
        </div>

        <div class="ml-4">
          <a href="https://app.moreathome.de" class="btn btn--primary" target="_blank">Login</a>
        </div>
      </div>
    </div>
  </header>
</template>
<script setup>
onMounted(() => {
  reviewsBadgeRibbon('badge-ribbon', {
    store: 'www.moreathome.de',
    size: 'small',
    mono: true,
  });
});
</script>
