import { default as imprint7kSS69wvmJMeta } from "/vercel/path1/pages/imprint.vue?macro=true";
import { default as indexzhmolsBfabMeta } from "/vercel/path1/pages/index.vue?macro=true";
import { default as privacyXnmh8a8hSIMeta } from "/vercel/path1/pages/privacy.vue?macro=true";
import { default as register3VIXDPK4aGMeta } from "/vercel/path1/pages/register.vue?macro=true";
import { default as termsmyl6hCuvzxMeta } from "/vercel/path1/pages/terms.vue?macro=true";
import { default as welcomeuYs8OlHLQaMeta } from "/vercel/path1/pages/welcome.vue?macro=true";
import { default as component_45stubjS4GBx8cgLMeta } from "/vercel/path1/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_typescript@5.6.2_vite@5.4.6_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubjS4GBx8cgL } from "/vercel/path1/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_typescript@5.6.2_vite@5.4.6_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "imprint___de",
    path: "/impressum",
    component: () => import("/vercel/path1/pages/imprint.vue")
  },
  {
    name: "imprint___en",
    path: "/en/imprint",
    component: () => import("/vercel/path1/pages/imprint.vue")
  },
  {
    name: "index___de",
    path: "/",
    component: () => import("/vercel/path1/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/vercel/path1/pages/index.vue")
  },
  {
    name: "privacy___de",
    path: "/datenschutz",
    component: () => import("/vercel/path1/pages/privacy.vue")
  },
  {
    name: "privacy___en",
    path: "/en/privacy",
    component: () => import("/vercel/path1/pages/privacy.vue")
  },
  {
    name: "register___de",
    path: "/anmelden",
    component: () => import("/vercel/path1/pages/register.vue")
  },
  {
    name: "register___en",
    path: "/en/register",
    component: () => import("/vercel/path1/pages/register.vue")
  },
  {
    name: "terms___de",
    path: "/agb",
    component: () => import("/vercel/path1/pages/terms.vue")
  },
  {
    name: "terms___en",
    path: "/en/terms",
    component: () => import("/vercel/path1/pages/terms.vue")
  },
  {
    name: "welcome___de",
    path: "/willkommen",
    component: () => import("/vercel/path1/pages/welcome.vue")
  },
  {
    name: "welcome___en",
    path: "/en/welcome",
    component: () => import("/vercel/path1/pages/welcome.vue")
  },
  {
    name: component_45stubjS4GBx8cgLMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubjS4GBx8cgL
  },
  {
    name: component_45stubjS4GBx8cgLMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubjS4GBx8cgL
  },
  {
    name: component_45stubjS4GBx8cgLMeta?.name,
    path: "/de-DE-sitemap.xml",
    component: component_45stubjS4GBx8cgL
  },
  {
    name: component_45stubjS4GBx8cgLMeta?.name,
    path: "/en-US-sitemap.xml",
    component: component_45stubjS4GBx8cgL
  }
]