import revive_payload_client_RB4DX1xBHe from "/vercel/path1/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_typescript@5.6.2_vite@5.4.6_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_LJj16qOxXV from "/vercel/path1/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_typescript@5.6.2_vite@5.4.6_vue-tsc@2.1.6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_9wP7Z76sR2 from "/vercel/path1/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_typescript@5.6.2_vite@5.4.6_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_6QauGz4sL8 from "/vercel/path1/node_modules/.pnpm/nuxt-site-config@2.2.18_vite@5.4.6_vue@3.5.6/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_oZ1c6YUqcI from "/vercel/path1/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_typescript@5.6.2_vite@5.4.6_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_VHdTANODMN from "/vercel/path1/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_typescript@5.6.2_vite@5.4.6_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_DgEMM6Ttbb from "/vercel/path1/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_typescript@5.6.2_vite@5.4.6_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_DDby4WrlJc from "/vercel/path1/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_typescript@5.6.2_vite@5.4.6_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_ElEmHCTbng from "/vercel/path1/node_modules/.pnpm/@pinia+nuxt@0.4.11_typescript@5.6.2_vue@3.5.6/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path1/.nuxt/components.plugin.mjs";
import prefetch_client_fjwys6BqlX from "/vercel/path1/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_typescript@5.6.2_vite@5.4.6_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_4LjoC6cYxK from "/vercel/path1/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.5_vue-router@4.4.5_vue@3.5.6/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_WBnl2t5gIN from "/vercel/path1/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-rc.5_vue-router@4.4.5_vue@3.5.6/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_YfYfGxrFmJ from "/vercel/path1/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.13.2_vue@3.5.6/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import bootstrap_client_zGQC9AE5yr from "/vercel/path1/plugins/bootstrap.client.js";
import sentry_client_shVUlIjFLk from "/vercel/path1/plugins/sentry.client.ts";
import validation_client_d1qP4NqBAJ from "/vercel/path1/plugins/validation.client.js";
export default [
  revive_payload_client_RB4DX1xBHe,
  unhead_LJj16qOxXV,
  router_9wP7Z76sR2,
  _0_siteConfig_6QauGz4sL8,
  payload_client_oZ1c6YUqcI,
  navigation_repaint_client_VHdTANODMN,
  check_outdated_build_client_DgEMM6Ttbb,
  chunk_reload_client_DDby4WrlJc,
  plugin_vue3_ElEmHCTbng,
  components_plugin_KR1HBZs4kY,
  prefetch_client_fjwys6BqlX,
  composition_4LjoC6cYxK,
  i18n_WBnl2t5gIN,
  plugin_YfYfGxrFmJ,
  bootstrap_client_zGQC9AE5yr,
  sentry_client_shVUlIjFLk,
  validation_client_d1qP4NqBAJ
]